@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'swiper/css/navigation';

:root {
  --swiper-navigation-size: 30px;
}

#comment-form-swiper-modal {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    color: rgba(0, 0, 0, 0.8);
    padding: 0 15px;
    background-color: white;
    z-index: 9999;
    border-radius: 50%;
  }

  .swiper-button-next {
    right: 2px;
  }

  .swiper-button-next::after {
    content: '>';
    position: absolute;
    top: 48%;
    left: 54%;
    transform: translate(-50%, -50%);
  }

  .swiper-button-prev {
    left: 2px;
  }

  .swiper-button-prev::after {
    content: '<';
    position: absolute;
    top: 48%;
    left: 48%;
    transform: translate(-50%, -50%);
  }

  .swiper-button-disabled {
    display: none;
  }
}

#comment-form-swiper {
  .swiper-button-next,
  .swiper-button-prev {
    color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
  }

  .swiper-button-next {
    right: 1px;
  }

  .swiper-button-next::after {
    content: '🠖';
  }

  .swiper-button-prev {
    left: 0px;
  }

  .swiper-button-prev::after {
    content: '🠔';
  }

  .swiper-button-disabled {
    display: none;
  }
}
